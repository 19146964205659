
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styles from './login-page.module.scss';

export const LoginPage = () => {

    const { loginWithRedirect } = useAuth0();

    return (
        <div className={styles.root}>
            <img 
                src="images/logo.png" 
                height="64px"
                alt="Semantic Evolution Logo" />
            <div className={styles.title}>Semantic Table Extract</div>
            <button className={styles.button} onClick={loginWithRedirect}>Log in</button>
        </div>
    );
}