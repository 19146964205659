import React from 'react';
import { Tooltip, Tag, Icon, Divider } from 'antd';
import { SeDocument } from 'model/se-document';
import dateformat from 'dateformat';

const TRUNCATE_LENGTH = 50;


export const buildColumns = (
	selectedDocumentId: string|undefined,
	loadDocument: (id: string, incTables: boolean) => void,
	downloadDocument: (id: string, fileName: string, fileExtension: string, fileType: 'pdf' | 'xlsx' | 'json') => Promise<void>,
) => [
	{
		title: 'File',
		dataIndex: 'fileName',
		key: 'fileName',
		render: (_: any, doc: SeDocument) => {
			if (doc.fileName.length > TRUNCATE_LENGTH) {
				return (
					<Tooltip title={doc.fileName}>
						<p className='document-list-name-label' style={{ marginTop: 'auto', marginBottom: 'auto' }}>{doc.fileName.substring(0, TRUNCATE_LENGTH)}...</p>
					</Tooltip>
				);
			} else {
				return <p className='document-list-name-label' style={{ marginTop: 'auto', marginBottom: 'auto' }}>{doc.fileName}</p>
			}
		},
		sorter: (a: SeDocument, b: SeDocument) => a.fileName < b.fileName ? -1 : 1,
		onFilter: (value: string, record: SeDocument) => record.fileName.indexOf(value) === 0,
	},
	{
		title: 'Uploaded',
		dataIndex: 'uploaded',
		key: 'uploaded',
		sorter: (a: SeDocument, b: SeDocument) => a.uploaded < b.uploaded ? -1 : 1,
		render: (_: any, doc: SeDocument) => { return dateformat(doc.uploaded, "dd mmm yyyy, HH:MM:ss Z")},
		defaultSortOrder: 'descend' as "descend" | "ascend" | undefined,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		sorter: (a: SeDocument, b: SeDocument) => a.status < b.status ? -1 : 1,
		render: (_: any, doc: SeDocument) => {

			if (doc.status === 'Complete') {
				return (<Tag className='document-list-status-tag' color={'green'}>{'	COMPLETE'}</Tag>);
			} else if (doc.status === 'Pending') {
				return (<Tag className='document-list-status-tag' color={'gold'}>{'PENDING'}</Tag>);
			} else if (doc.status === 'Processing') {
				return (<Tag className='document-list-status-tag' color={'lightblue'}>{'PROCESSING'}</Tag>);
			}  else if (doc.status === 'Error') {
				return (<Tag className='document-list-status-tag' color={'red'}>{'ERROR'}</Tag>);
			}

			return null;
		}
	},
	{
		title: 'View',
		key: 'view',
		className: 'center',
		render: (_: any, doc: SeDocument) => {
			if (doc.status === 'Complete') {
				return (<a className='document-list-view-link' onClick={() => loadDocument(doc.id, true)}>View</a>);
			} else if (doc.status === 'Processing') {
				return (<Icon type="loading" />);
			}

			return null;
		},
	},
	{
		title: 'Download',
		key: 'download',
		className: 'center',
		render: (_: any, doc: SeDocument) => {
			if (doc.status === 'Complete') {
				return (
					<span className='document-list-download-links'>
						{doc.hasJsonOutput ? <a onClick={() => downloadDocument(doc.id, doc.fileName, doc.jsonFileExtension, "json")}>JSON</a> : null}
						<Divider type="vertical" />
						{doc.hasExcelOutput ? <a onClick={() => downloadDocument(doc.id, doc.fileName, doc.excelFileExtension, "xlsx")}>Excel</a> : null}
						<Divider type="vertical" />
						{doc.hasPdfOutput ? <a onClick={() => downloadDocument(doc.id, doc.fileName, doc.pdfFileExtension, "pdf")}>PDF</a> : null}
					</span>
				);
			}

			return null;
		},
	}
];
