import React, { useCallback, useEffect, useState } from 'react';
import s from './document-list.module.scss';
import { Table, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { HttpUtils } from '../../../../utils/http/http-utils';
import { DocListHeader } from './controls/doc-list-header';
import { buildColumns } from './utils/build-columns';
import { SeDocument } from 'model/se-document';
import { useAuth0 } from '@auth0/auth0-react';


type DocumentListProps = {
	loadDocument: (id: string, incTables: boolean) => void;
}
export const DocumentList: React.FC<DocumentListProps> = ({ loadDocument }) => {
	const { getAccessTokenSilently } = useAuth0();

	let [selectedDocumentId, setSelectedDocumentId] = useState<string|undefined>();
	let [documents, setDocuments] = useState<SeDocument[]>();
	let [loading, setLoading] = useState(false);
	const [searchStr, setSearchStr] = useState('');

	const loadDocumentAndUpdateSelected = (id: string, incTables: boolean) => {
		loadDocument(id, incTables);
		setSelectedDocumentId(id);
	}

	const loadDocuments = useCallback(async (search: string) => {
		setLoading(true);
		const token = await getAccessTokenSilently();
		const [err, docs] = await HttpUtils.get<SeDocument[]>('Document', { search }, token)
		if (!!docs) {
			console.log(`docsLoaded: ${docs.length}`);
			for (let i = 0; i < docs.length; i++)
				docs[i].uniqueIndex = i;
			setDocuments(docs);
		} else {
			message.error(`${err}: could not get documents.`, 2);
		}
		setLoading(false);
	}, [setLoading, setDocuments, getAccessTokenSilently]);

	const uploadFiles = useCallback((info: UploadChangeParam<UploadFile>) => {
		if (info.file.status !== 'uploading') {
			console.log(info.file, info.fileList);
		}
		if (info.file.status === 'done') {
			message.success(`${info.file.name} file uploaded successfully - press 'Reload' to update the status of documents`, 4);
			loadDocuments(searchStr);
		} else if (info.file.status === 'error') {
			message.error(`${info.file.name} file upload failed.`, 2);
		}
	}, [loadDocuments, searchStr]);

	const downloadDocument = useCallback(async (id: string, fileName: string, fileExtension: string, fileType: 'pdf' | 'xlsx' | 'json') => {
		const token = await getAccessTokenSilently();
		const [err, blob] = await HttpUtils.getBlob<any>(`${fileType}/${id}`, { }, token);
		if (!!blob) {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${fileName}${fileExtension}`);
			// 3. Append to html page
			document.body.appendChild(link);
			// 4. Force download
			link.click();
			// 5. Clean up and remove the link
			document.body.removeChild(link);
		} else {
			message.error(`${err}: could not download file.`, 2);
		}
	}, [getAccessTokenSilently]);

	useEffect(() => {
		loadDocuments('');
	}, [loadDocuments]);

	return (
		<div className={s.top}>
			<Table
				rowKey={'uniqueIndex'}
				rowClassName={(record: SeDocument, index: number) => selectedDocumentId === record.id ? s.highlightedRow : s.unhighlightedRow}
				dataSource={documents}
				columns={buildColumns(selectedDocumentId, loadDocumentAndUpdateSelected, downloadDocument)}
				size={'small'}
				loading={loading}
				title={() => (
					<DocListHeader
						loadDocuments={loadDocuments}
						uploadFiles={uploadFiles}
						searchStr={searchStr}
						setSearchStr={setSearchStr}
					/>
				)} />;
		</div>
	)
}