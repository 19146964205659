import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import 'se-web-pdf-viewer/dist/index.css';
import 'antd/dist/antd.css';
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE || ''}
        redirectUri={window.location.origin}
        state='dsksdklclxefw3wq30i0pklcdewew9954gtfg'
    >
        <App />
    </Auth0Provider>, document.getElementById('root'));
