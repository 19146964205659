import React from 'react';
import Tour from 'reactour';

type TablesTourProps = {
	isTourOpen: boolean;
	onRequestClose: () => void;
}

export const TablesTour: React.FC<TablesTourProps> = (props) => {

	return (
    <>
		{ /* other stuff */ }
		< Tour
			steps = { steps }
			isOpen={props.isTourOpen}
			rounded={5}
			onRequestClose={props.onRequestClose} />
	</>
	)
}

const steps = [
	{
		selector: '#semantic-table-service-title',
		content: (<div><p><strong>Welcome to the Semantic Table Extract!</strong></p><p>It allows user to test out the Table API in a user-friendly web portal.</p></div>),
	},
	{
		selector: '#search-input',
		content: 'You can enter a search term here to search for specific documents, or leave it blank to return all the uploaded documents.',
	},
	{
		selector: '.ant-upload',
		content: "You can upload one or more documents to Semantic Table Extract using the 'Upload' button - just browse to the files you want and press 'Open'.",
	},
	{
		selector: '#search-reload',
		content: "The list does auto-refresh, so to check a document's latest status remember to refresh the search by hitting the RELOAD button.",
	},
	{
		selector: '.document-list-name-label',
		content: "Once you've uploaded a document it will appear in a row here.",
	},
	{
		selector: '.document-list-status-tag',
		content: "Documents can be in a status of 'Pending', 'Processing', 'Complete' or 'Error'.",
	},
	{
		selector: '.document-list-open-link',
		content: "You can view a document by clicking on the 'Open' link - the document and its tables will be shown in the viewer.",
	},
	{
		selector: '#very-pdf-viewer',
		content: 'Once the document is loaded in the viewer you can control the page and zoom using the toolbar at the top...',
	},
	{
		selector: '#very-pdf-viewer',
		content: '... or search the document to find specific phrases using the search functionality at the top left.',
	},
	{
		selector: '.document-list-download-links',
		content: 'Finally, you can download results in json, pdf or Excel format by clicking on the relevant link here.',
	},
	{
		content: 'Have fun!',
	}
]