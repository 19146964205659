import React, { useEffect, useState, useCallback } from 'react';
import { Divider, Upload, Button, Icon, Input } from 'antd';
import { HttpUtils } from '../../../../../utils/http/http-utils';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useAuth0 } from '@auth0/auth0-react';

type DocListHeaderProps = {
	loadDocuments: (search: string) => Promise<void>;
	uploadFiles: (info: UploadChangeParam<UploadFile>) => void;

	searchStr: string;
	setSearchStr: (searchStr: string) => void;
}
export const DocListHeader: React.FC<DocListHeaderProps> = ({ loadDocuments, uploadFiles, searchStr, setSearchStr }) => {
	const { user, getAccessTokenSilently } = useAuth0()
	
	const [token, setToken] = useState('');
	
	useEffect(() => {
		const getToken = async () => { 
			const token = await getAccessTokenSilently(); 
			setToken(token);
		}

		getToken();

	}, [getAccessTokenSilently])
	;

	const onSearch = useCallback((str: string) => {
		loadDocuments(str);
		setSearchStr(str);
	}, [loadDocuments, setSearchStr]);
	const onReload = useCallback(() => {
		loadDocuments(searchStr);
	}, [loadDocuments, searchStr]);
	
	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<img src="favicon-32x32.png" alt="Semantic Evolution Logo" />
			<p style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: 18, fontWeight: 'bold', marginLeft: 10 }}>Semantic Table Extract</p>
			<Divider type="vertical" style={{ height: 33 }} />
			<Input.Search
				id="search-input"
				placeholder="search"
				onSearch={onSearch}
				style={{ width: 150 }}
			/>
			<Divider type="vertical" style={{ height: 33 }} />
			<Button id="search-reload" onClick={onReload}>
				<Icon type="reload" /> Reload
			</Button>
			<Divider type="vertical" style={{ height: 33 }} />
			<Upload
				action={HttpUtils.buildApiUrl('document')}
				headers={{ "Access-Control-Allow-Origin": '*', Authorization: 'Bearer ' + token }}
				onChange={uploadFiles}
				showUploadList={false}
				multiple={true}>
				<Button>
					<Icon type="upload" /> Upload
				</Button>
			</Upload>
			<p style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: 5, flexGrow: 1, textAlign: 'right' }}>{`${user!.name}`}</p>
		</div>
	);
}