import React, { useCallback, useState } from 'react';
import s from './main-page.module.scss';
import { message, Button, Icon, Tooltip } from 'antd';

import { ISemanticTables, useTableTagger } from 'se-web-table-tagging';
import { VeryPdf, DocumentSource } from 'se-web-pdf-viewer';
import { DocumentList } from './controls/document-list/document-list';
import { HttpUtils } from '../../utils/http/http-utils';
import { TablesTour } from './tables-tour';
import { useAuth0 } from '@auth0/auth0-react';

interface ServerJsonResponse {
	data: ISemanticTables
}

const PDFJS: any = 'PDFJS';
var pdfjsLib: any = window[PDFJS];
pdfjsLib.workerSrc = 'static/js/main.chunk.worker.js';


export const MainPage: React.FC = () => {
	const { user, getAccessTokenSilently } = useAuth0();
	const [pdfSource, setPdfSource] = useState<DocumentSource>(null);
	const [triggerPreloadState, setTriggerPreloadState] = useState(false);
	const [isDocLoading, setIsDocLoading] = useState(false);
	const [isTourOpen, setIsTourOpen] = useState(false);
	const tableTagger = useTableTagger();

	const [buttonText, setButtonText]= useState("Hide Tables");

	function changeButtonText() {
		setButtonText(prevState => prevState === "Hide Tables" ? "Show Tables" : "Hide Tables")
	}
	function setHtmlTableVisibility() {
		var obj = document.getElementsByClassName("se-table");
		for (var i = 0; i < obj.length; i++) {
			(obj[i] as HTMLElement).style.display = ((obj[i] as HTMLElement).style.display === "") ? 'none' : "";
		}
	}

	let onError = (msg: string) => {
		message.error(msg, 2);
	}

	let loadDocument = async (id: string, incTables: boolean) => {
		const token = await getAccessTokenSilently();
		setIsDocLoading(true);
		setTriggerPreloadState(true);
		tableTagger.load({ tables: [] });

		const pdfSource = {
			url: HttpUtils.buildApiUrl(`pdf/${id}`),
			httpHeaders: { "Authorization": 'Bearer ' + token },
		};

		if (incTables) {
			const [err, data] = await HttpUtils.get<ISemanticTables>(`json/${id}`, {}, token);
			if (!!data) {
				tableTagger.load(data);
				setPdfSource(pdfSource);
			} else {
				onError(`Error loading pdf / table data: ${err}.`);
			}
		} else {
			setPdfSource(pdfSource);
		}
	}

	const onDocumentLoaded = useCallback(() => {
		setIsDocLoading(false);
	}, [setIsDocLoading]);
	
	const onToggleTablesVisibility = useCallback(() => {
		setHtmlTableVisibility();
		changeButtonText();
	}, [setButtonText]);

	return (
		<div className={s.root}>
			<div className={s.list} style={{ display: user !== null ? 'unset' : 'hidden' }}>
				<DocumentList
					loadDocument={loadDocument} />
				{ isDocLoading && <div className={s.listDisabled}/> }
			</div>
			<div className="se-table-toolbar">
			<Button id="toggle-table-visibility" onClick={onToggleTablesVisibility} style={{float: 'right'}}>
				<Icon type="table" />{buttonText}
			</Button>
			</div>
			<div id='very-pdf-viewer' className={s.viewer} style={{ display: user !== null ? 'unset' : 'hidden' }}>
				<VeryPdf
					documentSource={pdfSource}
					pageRender={tableTagger.pageRender}
					onDocumentLoaded={onDocumentLoaded}
					onError={onError}
					triggerPreloadState={triggerPreloadState}
					setTriggerPreloadState={setTriggerPreloadState}
					/>
			</div>
			<TablesTour
				isTourOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
			/>

			<Tooltip title='Take the Tour!' placement='right'>
				<Button style={{ position: 'absolute', left: 10, bottom: 10 }} type="primary" shape="circle" icon="bulb" onClick={() => setIsTourOpen(true)} />
			</Tooltip>

		</div>

	)
}