import { useAuth0 } from '@auth0/auth0-react';
import { LoginPage } from 'features/login/login-page';
import React from 'react';
import './App.css';
import { MainPage } from './features/main/main-page';

export const App: React.FC = () => {

  const { isLoading, error, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isAuthenticated) {
    return <LoginPage />;

  }

  return (<MainPage />)
}
