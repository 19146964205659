import axios from 'axios';

export class HttpUtils {
	private static readonly baseUrl = process.env.REACT_APP_API;
	private static readonly http = axios.create({
		baseURL: HttpUtils.baseUrl,
		timeout: 60000,
	});

	static buildApiUrl(relUrl: string) { return `${this.baseUrl}/${relUrl}`; }

	static async get<T>(relUrl: string, params: any, token?: any): Promise<[any, T | null]> {
		const headers = { Authorization: `Bearer ${token}` };
		try {
			const response = await this.http.get<T>(relUrl, { params, headers });
			return [null, response.data];
		} catch (err) {
			return [err, null];
		}
	}

	static async getBlob<T>(relUrl: string, params: any, token?: string): Promise<[any, Blob | null]> {
		
		const headers = { Authorization: `Bearer ${token}` };
		let fetchData = {
			method: 'GET',
			headers: headers
		}

		try {
			const response = await fetch(HttpUtils.baseUrl + '/' + relUrl, fetchData);
			var b = await response.blob();
			return [null, new Blob([b])];
		} catch (err) {
			return [err, null];
		}
	}

	static async post<T>(relUrl: string, params: any, token?: any): Promise<[any, T | null]> {
		const headers = { Authorization: `Bearer ${token}` };
		try {
			const response = await this.http.post<T>(relUrl, params, { headers });
			return [null, response.data];
		} catch (err) {
			return [err, null];
		}
	}
}